import * as React from "react";
import logoScinteco from "../../images/partners/w3geo-logo.png";
import { Row, Col, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export default function Customers() {
  return (
    <Container className="mt-5">
      <Row style={{ paddingBottom: "1rem" }}>
        <Col>
          <h3>
            <FormattedMessage id="home.partners" />
          </h3>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col>
          <a href="https://w3geo.at" target="_blank" rel="noreferrer">
            <img
              src={logoScinteco}
              alt="Logo w3geo"
              style={{ float: "none" }}
              width="300"
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
}
