import React from "react";
import { Col } from "react-bootstrap";

export default function ServicesCardColumn({ children }) {
  return (
    <Col md={12} lg={4} className="column-spacing">
      {children}
    </Col>
  );
}
