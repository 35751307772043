import * as React from "react";
import Layout from "../components/Layout";
import ImageContainer from "../components/ImageContainer";
import CompanyShortDesc from "../components/CompanyShortDesc/CompanyShorDesc";
import Services from "../components/Services/Services";
import image from "../images/open-source.jpg";
import { Helmet } from "react-helmet";
import useFormattedMsg from "../hooks/useFormattedMsg";
import Customers from "../components/Customers/Customers";
import Partners from "../components/Partners/Partners";

export default function IndexPage() {
  const title = useFormattedMsg("home.title");
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>

      <Layout>
        <ImageContainer image={image} alt="Heading" />
        <CompanyShortDesc />
        <Customers />
        <Partners />
        <Services />
      </Layout>
    </>
  );
}
