import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { navigate } from "gatsby";
import useLangAwarePath from "../../hooks/useLangAwarePath";

export default function CompanyShortDesc() {
  const pathFactory = useLangAwarePath();

  return (
    <div
      style={{
        backgroundColor: "#EFF3E6 ",
      }}
      className="py-5"
    >
      <Container>
        <Row style={{ paddingBottom: "1rem" }}>
          <Col>
            <h1>
              <FormattedMessage id="home.company_short_heading" />
            </h1>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "1rem" }}>
          <Col>
            <FormattedMessage id="home.company_short_descr" />
          </Col>
        </Row>
        <Row style={{ paddingBottom: "1rem" }}>
          <Col>
            <h3>
              <FormattedMessage id="home.oss_facts" />
            </h3>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "2rem" }}>
          <Col>
            <ul>
              <li>
                <FormattedMessage id="home.oss_fact_1" />
              </li>
              <li>
                <FormattedMessage id="home.oss_fact_2" />
              </li>
              <li>
                <FormattedMessage id="home.oss_fact_3" />
              </li>
              <li>
                <FormattedMessage id="home.oss_fact_4" />
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <button
              type="button"
              className="btn btn-outline-dark"
              onClick={() => navigate(pathFactory("/services"))}
            >
              <FormattedMessage id="home.about_us" />
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
