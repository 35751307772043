import * as React from "react";
import { Row } from "react-bootstrap";
import ServicesCard from "../ServicesCard/ServicesCard";
import {
  faCode,
  faServer,
  faPersonChalkboard,
} from "@fortawesome/free-solid-svg-icons";
import useFormattedMsg from "../../hooks/useFormattedMsg";
import PageSection from "../PageSection/PageSection";
import ServicesCardColumn from "./ServicesCardColumn/ServicesCardColumn";

export default function Services() {
  const consultingServiceText = useFormattedMsg("home.consulting_service_text");
  const swDevServiceText = useFormattedMsg("home.sw_dev_service_text");
  const serviceAdminServiceText = useFormattedMsg(
    "home.server_admin_service_text"
  );
  const ourServicesLabel = useFormattedMsg("common.our_services");
  const consultingLabel = useFormattedMsg("home.consulting");
  const swDevLabel = useFormattedMsg("home.sw_development");
  const serverAdminLabel = useFormattedMsg("home.server_adminstration");

  return (
    <PageSection title={ourServicesLabel}>
      <Row>
        <ServicesCardColumn>
          <ServicesCard icon={faPersonChalkboard} title={consultingLabel}>
            {consultingServiceText}
          </ServicesCard>
        </ServicesCardColumn>
        <ServicesCardColumn>
          <ServicesCard icon={faCode} title={swDevLabel}>
            {swDevServiceText}
          </ServicesCard>
        </ServicesCardColumn>
        <ServicesCardColumn>
          <ServicesCard icon={faServer} title={serverAdminLabel}>
            {serviceAdminServiceText}
          </ServicesCard>
        </ServicesCardColumn>
      </Row>
    </PageSection>
  );
}
