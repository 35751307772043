import * as React from "react";
import { useIntl } from "react-intl";
import statLogoDE from "../../images/customers/logo_statistik.gif";
import statLogoEN from "../../images/customers/logo_statistik_en.gif";

export default function StatAustriaLink() {
  let statUrl;
  let statLogo;

  const intl = useIntl();
  if (intl.locale === "de") {
    statUrl = "https://www.statistik.at/de";
    statLogo = statLogoDE;
  } else {
    statUrl = "https://www.statistik.at/en";
    statLogo = statLogoEN;
  }

  return (
    <a href={statUrl} target="_blank" rel="noreferrer">
      <img
        src={statLogo}
        alt="Logo Statistic Austria"
        style={{ float: "none" }}
        width="300"
      />
    </a>
  );
}
