import useLang from "./useLang";

export default function useLangAwarePath() {
  const lang = useLang();

  const createLangAwarePath = (path) => {
    return `${lang}${path}`;
  };

  return createLangAwarePath;
}
