import * as React from "react";
import StatAustriaLink from "../StatAustriaLink/StatAustriaLink.js";
import logoScinteco from "../../images/customers/scinteco_logo_light_bg.svg";
import { Row, Col, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export default function Customers() {
  return (
    <Container className="mt-5">
      <Row style={{ paddingBottom: "1rem" }}>
        <Col>
          <h3>
            <FormattedMessage id="home.customers" />
          </h3>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col>
          <StatAustriaLink></StatAustriaLink>
        </Col>
        <Col>
          <a href="https://www.scinteco.com" target="_blank" rel="noreferrer">
            <img
              src={logoScinteco}
              alt="Logo Scinteco"
              style={{ float: "none" }}
              width="300"
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
}
