import React from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import useLangAwarePath from "../../hooks/useLangAwarePath";
import { navigate } from "gatsby";

function ServicesCard(props) {
  const { children: content, icon, title } = props;
  const pathFactory = useLangAwarePath();

  return (
    <Card style={{ boxShadow: "3px 5px 10px silver" }}>
      <Card.Body style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ height: "150px" }}
          className="d-flex justify-content-center align-items-center"
        >
          <FontAwesomeIcon size="6x" icon={icon} />
        </div>
        <div
          style={{ height: "50px", fontSize: "24px", fontWeight: "bold" }}
          className="d-flex justify-content-center align-items-center"
        >
          {title}
        </div>
        <div className="text-center pb-3">{content}</div>
        <div className="text-center">
          <button
            type="button"
            className="btn btn-outline-dark"
            onClick={() => navigate(pathFactory("/services"))}
          >
            <FormattedMessage id="home.learn_more" />
          </button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ServicesCard;
